import React, { Fragment, useState } from 'react';
import { css } from '@linaria/core';
import { usePortfolio } from './PortfolioHooks';
import { ContentCard, List } from 'src/components/styles';
import { Expander, FlexRow } from 'src/composing/Flex';
import { UnstyledButton } from 'src/composing/CSButton';
import { TextCaption, TextDisplaySmall } from 'src/composing/CSText';
import { SpacerV } from 'src/composing/Spacing';
import { SkeletonWrapper } from 'src/components/SuspenseSkeleton';
import { useGetUserWatchlist } from '@commonstock/common/src/api/user';
import { Colors } from 'src/theme/constants';
import { ProfilePositionsSkeleton } from 'src/scopes/profile/Profile.skeleton';
import EmptyListItem from 'src/components/EmptyListItem';
import ListHeader from 'src/components/ListHeader';
import WatchlistItem, { ProfileWatchlistItem } from './WatchlistItem';
import { useUserProfile } from 'src/scopes/profile/ProfileHooks';
import { ProfileIndexProps } from 'pages/[username]';

function Watchlist({
  small
}: {
  small?: boolean;
}) {
  const {
    watchlist
  } = usePortfolio();
  return <List>
      <ListHeader title="Watchlist" greyHeader={!small} small={small} />
      {watchlist.map(asset => <WatchlistItem key={asset.id} asset={asset} />)}
      {!watchlist.length && <EmptyListItem text={'Search for assets and add them to your watchlist.'} />}
    </List>;
}

type Props = {
  limit?: number;
} & Pick<ProfileIndexProps, 'publicWatchlist' | 'publicProfile'>;
export function ProfileWatchlist({
  limit = 4,
  publicProfile,
  publicWatchlist
}: Props) {
  const {
    profile
  } = useUserProfile(publicProfile);
  const [watchlist,, watchlistPending] = useGetUserWatchlist({
    meta: {
      user_uuid: profile?.user_uuid || ''
    }
  }, {
    paused: !profile
  });
  const [isExpanded, setIsExpanded] = useState(false);

  const onClick = () => setIsExpanded(prev => !prev);

  const userWatchlist = isExpanded ? watchlist || publicWatchlist : (watchlist || publicWatchlist)?.slice(0, limit);
  return <SkeletonWrapper pending={Boolean(watchlistPending)} skeleton={<ProfilePositionsSkeleton />} failed={!watchlistPending && !watchlist?.length}>
      <ContentCard>
        <FlexRow>
          <TextDisplaySmall role="heading">Watchlist</TextDisplaySmall>
          <Expander />
          <TextCaption color={Colors.TextSecondary}>Price</TextCaption>
          <TextCaption color={Colors.TextSecondary} className={changeClass}>
            1d change
          </TextCaption>
        </FlexRow>
        <div id="watchlist" role="list">
          {userWatchlist?.map(asset => <Fragment key={asset.id}>
              <SpacerV />
              <ProfileWatchlistItem asset={asset} />
            </Fragment>)}
        </div>
        {watchlist && watchlist.length > limit && <>
            <SpacerV />
            <UnstyledButton className={buttonClass} onClick={onClick} role="button" aria-controls="watchlist" aria-expanded={isExpanded ? 'true' : 'false'} data-cy="expand-list">
              <TextCaption color={Colors.TextLinks}>{isExpanded ? 'Collapse' : `Show all`}</TextCaption>
            </UnstyledButton>
          </>}
      </ContentCard>
    </SkeletonWrapper>;
}
export default Watchlist;
const buttonClass = "bsv2o0m";
const changeClass = "cflmz8n";

require("../../../.linaria-cache/packages/oxcart/src/scopes/portfolio/Watchlist.linaria.module.css");